import Avatar from "../../ui/avatar";
import Logo from "../../logo";
import Link from "next/link";
import dynamic from "next/dynamic";
import React, { useEffect, useState, useRef } from "react";
import SplitPane from "react-split-pane";
import juice from "juice";
import { monokai } from "./themes";
import { useAuth } from "../../auth";
import { Button, ButtonLink } from "../../ui";

const isServer = typeof window === "undefined";
const EditorLayout = ({
  subtitle = "Inline, send and test HTML email templates",
  children,
  save = null,
  download = null,
  share = null,
  send = null,
  height = "min-h-screen",
  isPublic = false,
  setting = null
}) => {
  const { user } = useAuth();
  return <>
    {/* divide-y divide-gray-700 */}
    <div className={`${height} flex flex-col overflow-hidden`}>
      {/* primary nav */}
      <nav className="bg-postdrop-dark">
        <div className="w-full mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-24 sm:h-12 relative">
            <div className="relative z-10 px-2 flex lg:px-0 w-full">
              <div className="sm:flex-shrink-0 flex items-center w-full sm:w-auto">
                <div className="relative z-0 flex-1 items-center justify-center absolute sm:inset-0 hidden sm:flex">
                  <Link href={isPublic ? "/" : "/templates"}>

                    <Logo height="28px" />

                  </Link>
                </div>
                <div className="relative z-0 flex-1 px-2 items-center justify-center sm:absolute sm:inset-0 sm:hidden flex flex-col w-full">
                  <Link href={isPublic ? "/" : "/templates"}>

                    <Logo height="24px" />

                  </Link>
                  <div className="w-full sm:max-w-xs text-center">
                    <h1 className="text-white text-sm sm:text mt-2">
                      {subtitle}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
            {/* absolute to center - visible on desktop*/}
            <div className="relative z-0 flex-1 px-2 items-center justify-center sm:absolute sm:inset-0 hidden sm:flex">
              <div className="w-full sm:max-w-xs text-center">
                <h1 className="text-white text-sm sm:text">{subtitle}</h1>
              </div>
            </div>
            <div className="sm:flex sm:relative absolute right-0 top-4 sm:top-0 z-20">
              <div className="ml-2 flex items-center text-sm sm:text">
                {/* Profile dropdown */}
                {!user || isPublic ? (
                  <>
                    <Link
                      href="/login"
                      className="inline-flex text-blue-light hover:text-white focus:outline-none whitespace-nowrap py-1 px-2">

                      Login

                    </Link>
                    <Link
                      href="/signup"
                      className="inline-flex text-blue-light hover:text-white focus:outline-none whitespace-nowrap py-1 px-2">

                      Sign Up

                    </Link>
                  </>
                ) : (
                  <div className="relative block">
                    <Avatar />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>
      {/* secondary nav */}
      <header className="bg-postdrop-dark border-b border-gray-700">
        <div className="w-full mx-auto py-1 px-2 sm:px-8 flex justify-between">
          <nav className="flex space-x-2" aria-label="Save">
            {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}
            {save}
            {/* <a
              href="#"
              className="bg-gray-900 text-white rounded-md py-2 px-3 inline-flex items-center text-sm font-medium">
              Save
            </a> */}
          </nav>
          <nav className="flex space-x-4 text-sm" aria-label="Actions">
            {setting}
            {download}
            {share}
            {send}
          </nav>
        </div>
      </header>

      <main className="flex-auto overflow-visible flex flex-col bg-postdrop-editor relative">
        {children}
      </main>
    </div>
  </>;
};

export default EditorLayout;

const MonacoEditor = dynamic(() => import("@monaco-editor/react"), {
  ssr: false,
});

export const setTheme = (cb) => async (editor, monaco) => {
  try {
    monaco.editor.defineTheme("postdrop", monokai);
    monaco.editor.setTheme("postdrop");
  } catch (error) { }
  cb(editor, monaco);
};

export const CodeEditor = ({
  language = "html",
  loaded = false,
  onChange,
  onMount,
  value,
  settingValues,
  editorRef,
}) => {
  const monacoRef = useRef(null)
  const manocoEditor = monacoRef.current
  useEffect(() => {
    if (manocoEditor) {
      manocoEditor.languages.registerCompletionItemProvider('html', {
        triggerCharacters: ['>'],
        provideCompletionItems: (model, position) => {
          const codePre: string = model.getValueInRange({
            startLineNumber: position.lineNumber,
            startColumn: 1,
            endLineNumber: position.lineNumber,
            endColumn: position.column,
          });
          const tag = codePre.match(/.*<(\w+)>$/)?.[1];
          if (!tag) {
            return {};
          }
          const word = model.getWordUntilPosition(position);
          return {
            suggestions: [
              {
                label: `</${tag}>`,
                kind: manocoEditor.languages.CompletionItemKind.EnumMember,
                insertText: `</${tag}>`,
                range: {
                  startLineNumber: position.lineNumber,
                  endLineNumber: position.lineNumber,
                  startColumn: word.startColumn,
                  endColumn: word.endColumn,
                },
              },
            ],
          };
        },
      });
    }
  }, [manocoEditor])

  return (
    <div
      className="flex-1 h-full w-full flex flex-col"
      style={{ background: "#1e1f22" }}
    >
      <div className="w-full pt-1 pb-1 pl-9">
        <p className="text-gray-200 text-xs">{language.toUpperCase()}</p>
      </div>
      <MonacoEditor
        wrapperClassName={`${loaded ? "postdrop-editor-loaded" : "postdrop-editor-unloaded"
          } postdrop-editor`}
        height="100%"
        language={language}
        theme="postdrop"
        value={value}
        options={{
          minimap: {
            enabled: false,
          },
          // automaticLayout: true,
          lineNumbers: settingValues?.line_numbers ? 'on' : 'off',
          fontSize: settingValues?.font_size || 12,
          wordWrap: settingValues?.word_wrap || false,
          insertSpaces: settingValues?.indent === 'SPACES' ? true : false || true,
          quickSuggestions: {
            other: settingValues?.auto_complete_html || false,
            comments: settingValues?.auto_complete_html || false,
            strings: settingValues?.auto_complete_html || false
          },
          suggestOnTriggerCharacters: settingValues?.auto_complete_html || false,
          tabCompletion: settingValues?.auto_complete_html ? "on" : "off" || "off",
          "semanticHighlighting.enabled": true,
        }}
        onChange={onChange}
        onMount={(editor, monaco) => {
          if (editor && editorRef) {
            editorRef.current = editor
          }
          if (monaco && monacoRef) {
            monacoRef.current = monaco
          }
          setTheme(onMount)(editor, monaco)
        }}
      />
    </div>
  );
};

export const ReadOnlyEditor = ({
  value,
  language = "html",
  height = "500px",
}) => {
  const editor = useRef(null);
  useEffect(() => {
    if (editor.current) {
      editor.current.setValue(value);
    }
  }, [editor, value]);
  return (
    <MonacoEditor
      height={height}
      language={language}
      theme="postdrop"
      value={value}
      options={{
        minimap: {
          enabled: false,
        },
        fontSize: 12,
      }}
      onChange={() => { }}
      onMount={setTheme((e) => {
        editor.current = e;
        e.updateOptions({ readOnly: true });
      })}
    />
  );
};

const panelDefaults = {
  vertical: "50%",
  horizontal: "50%",
};
const SIZE_VERTICAL = "editor.pane.vertical";
const SIZE_HORIZONTAL = "editor.pane.horizontal";
const getSizings = () => {
  if (typeof window === undefined) {
    return panelDefaults;
  }

  try {
    const vertical = parseInt(window.localStorage.getItem(SIZE_VERTICAL), 10);
    const horizontal = parseInt(
      window.localStorage.getItem(SIZE_HORIZONTAL),
      10
    );

    return {
      vertical,
      horizontal,
    };
  } catch (_error) {
    return panelDefaults;
  }
};

const setPanelSize = (name) => (size) => {
  if (typeof localStorage === "object") {
    try {
      window.localStorage.setItem(name, size);
    } catch (_e) {
      return;
    }
  }
};

export const PanePage = ({ children }) => {
  const checkSize = useRef(true);
  const [isDragging, setDrag] = useState(false);
  const [verticalSize, setVerticalSize] = useState(null);
  useEffect(() => {
    if (!isServer && checkSize.current) {
      checkSize.current = false;
      const { vertical } = getSizings();
      setVerticalSize(vertical);
    }
  }, [isServer, checkSize]);
  return (
    <SplitPane
      split="vertical"
      primary="second"
      className={isDragging ? "primary is-dragging" : "primary"}
      minSize="10%"
      defaultSize={verticalSize || panelDefaults.vertical}
      onChange={setPanelSize(SIZE_VERTICAL)}
      onDragStarted={() => setDrag(true)}
      onDragFinished={() => setDrag(false)}
    >
      {/* expects: [SplitPane(html,css), Preview] */}
      {children}
    </SplitPane>
  );
};

export const PaneEditor = ({ children }) => {
  const checkSize = useRef(true);
  const [horizontalSize, setHorizontalSize] = useState(null);
  useEffect(() => {
    if (!isServer && checkSize.current) {
      checkSize.current = false;
      const { horizontal } = getSizings();
      setHorizontalSize(horizontal);
    }
  }, [isServer, checkSize]);
  return (
    <SplitPane
      split="horizontal"
      minSize="10%"
      defaultSize={horizontalSize || panelDefaults.horizontal}
      onChange={setPanelSize(SIZE_HORIZONTAL)}
    >
      {/* expected [Editor, Editor] */}
      {children}
    </SplitPane>
  );
};

export const Iframe = ({
  onlyFrame = false,
  src,
  css,
  sandbox = "",
  ...attrs
}) => {
  const ref = useRef();
  const [markup, setMarkup] = useState("");
  useEffect(() => {
    try {
      const result = juice(src, { extraCss: css || "" });
      setMarkup(result);
    } catch (_error) { }
  }, [src, css]);

  if (onlyFrame) {
    return (
      <iframe
        className={attrs.className}
        srcDoc={markup}
        frameBorder="0"
        ref={ref}
        {...attrs}
        sandbox={sandbox}
        title="preview"
        data-hj-allow-iframe=""
      />
    );
  }

  return (
    <div className="relative flex-1 h-full w-full flex flex-col">
      <iframe
        frameBorder="0"
        ref={ref}
        referrerPolicy="no-referrer"
        sandbox="allow-popups allow-popups-to-escape-sandbox allow-forms"
        {...attrs}
        data-hj-allow-iframe={""}
        srcDoc={markup}
      />
      <div className="absolute inset-0 hidden">
        <div className="absolute inset-0" />
      </div>
    </div>
  );
};

export const PublicShareLayout = ({
  subtitle = "Inline, send and test HTML email templates",
  children,
  save = null,
  download = null,
  share = null,
  send = null,
  height = "min-h-screen",
}) => {
  return <>
    {/* divide-y divide-gray-700 */}
    <div className={`${height} flex flex-col overflow-hidden`}>
      {/* primary nav */}
      <nav className="bg-postdrop-dark">
        <div className="w-full mx-auto px-4 md:px-6 lg:px-8">
          <div className="flex items-center justify-between h-32 md:h-12 relative">
            <div className="relative z-10 px-2 flex lg:px-0 w-full">
              <div className="md:flex-shrink-0 flex items-center w-full md:w-auto">
                <div className="relative z-0 flex-1 px-2 items-center justify-center absolute md:inset-0 hidden md:flex">
                  <Link href="/">

                    <Logo height="28px" />

                  </Link>
                </div>
                <div className="relative z-0 flex-1 px-2 items-center justify-center md:absolute md:inset-0 md:hidden flex flex-col w-full">
                  <Link href="/">

                    <Logo height="24px" />

                  </Link>
                  <div className="w-full md:max-w-md text-center">
                    <p className="text-white text-sm md:text my-3 truncate">
                      {subtitle}
                    </p>
                    <p className="text-white text-sm md:text mb-1">
                      {download}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* absolute to center - visible on desktop*/}
            <div className="relative z-0 flex-1 px-2 items-center justify-center md:absolute md:inset-0 hidden md:flex">
              <div className="w-full md:max-w-sm text-center">
                <p className="text-white text-sm truncate md:text">
                  {subtitle}
                </p>
              </div>
            </div>
            <div className="md:relative absolute right-0 top-4 md:top-0 z-20 hidden md:flex">
              <div className="ml-2 flex items-center">{download}</div>
            </div>
          </div>
        </div>
      </nav>
      <main className="flex-auto overflow-visible flex flex-col bg-postdrop-editor relative">
        {children}
      </main>
    </div>
  </>;
};
